import React from 'react';
import { List, Spoiler, ThemeIcon } from '@mantine/core';
import { CircleCheck } from 'tabler-icons-react';
import Layout from '../../components/Layout';
import NavOne from '../../components/NavOne';
import PageHeader from '../../components/PageHeader';
import Footer from '../../components/Footer';
import Tabs from '../../components/Tabs';
import team1 from '../../assets/images/team-1-1.jpg';
import akodu from '../../assets/images/departments/fam-med/akodu.jpg';
import adejimi from '../../assets/images/departments/fam-med/adejimi.jpg';
import asiegbu from '../../assets/images/departments/fam-med/asiegbu.jpg';
import fadipe from '../../assets/images/departments/fam-med/fadipe.jpg';
import nebe from '../../assets/images/departments/fam-med/nebe.jpg';
import ogunleye from '../../assets/images/departments/fam-med/ogunleye.jpg';
import olokodana from '../../assets/images/departments/fam-med/olokodana.jpg';
import orumbie from '../../assets/images/departments/fam-med/orumbie.jpg';

const EntDept = () => {
  const services = [
    {
      name: 'Ear Nose & Throat Endoscopy unit',
    },
    {
      name: 'Otomicroscopy/Temporal Bone laboratory',
    },
    {
      name: 'Audiometry',
    },
    
  ];

  const subunits = [
    {
      name: 'General ENT',
    },
    {
      name: 'Head & Neck Surgery',
    },
    {
      name: 'Laryngology',
    },
    {
      name: 'Otology',
    },
    {
      name:
        'Rhinology',
    },
    {
      name:
        'Audiology & Vestibular Disorders',
    },
    {
      name: 'Speech Pathology',
    },
    
  ];

  const collaboration = [
    {
      name: 'Neurosurgery in the areas of Skull base surgeries, transphenoidal endoscopic procedures',
    },
    {
      name: 'Plastic & Reconstructive Surgery in Surgical Oncology and flap reconstruction and Multidisciplinary management (MDT) of Head and Neck Cancers',
    },
    {
      name: 'Anatomic and Molecular Pathology in MDT of Head and Neck Cancer',
    },
    {
      name: 'Radiation and Medical Oncology in MDT Head and Neck Cancer',
    },
    {
      name:
        'Cardiothoracic team ',
    },
  ];


  return (
    <Layout pageTitle='Department Of Ear, Nose And Throat | LUTH'>
      <NavOne />
      <PageHeader title='Department Of Ear, Nose And Throat' />
      <section className='course-details'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='course-details__content'>
                <div className='course-details__top'>
                  <div className='course-details__top-left'>
                    <h2 className='course-details__title'>
                      Department Of Ear, Nose And Throat
                    </h2>
                  </div>
                </div>
                <Tabs>
                  <div label='Overview'>
                    <Spoiler
                      maxHeight={300}
                      hideLabel='Hide'
                      showLabel='Show More'
                    >
                      <h4>History of Otorhinollaryngology in LUTH</h4>
                      <p className='course-details__tab-text'>
                        The Lagos University Teaching Hospital was established
                        in 1962 by the Federal Government of Nigeria. (Lagos
                        University Teaching Hospital Act 1961) <br /> From the
                        inception of the Hospital, Otorhinolaryngology otherwise
                        known as E.N.T was established as a unit in the
                        Department of Surgery. The unit was operated by
                        Associate Lecturers and Part Time Consultants namely-
                        Dr. Zaleska and Dr Phil-Ebosie. <br /> In 1969, Dr.
                        Murkjarjee was employed as the first full-time
                        Consultant ENT Surgeon to run the Otolaryngology unit
                        and in 1969, Dr. P.A Okeowo joined the unit as a Senior
                        House Officer and was later sent for training in Britain
                        by the Hospital. He came back in 1973 with FRCS degree
                        and was appointed a Senior Registrar and later made a
                        Consultant in 1974. <br />
                        The following doctors joined the unit as Senior House
                        Officer In Otorhinolaryngology, these included : Drs.
                        Adekunle Okupe, Taiwo Ijaduola, Odunsi , Nelson Daniel,
                        Oriola Okeni and A. Olabiyi . These doctors later went
                        to Britain for Residency Training Programme in ORL. Dr.
                        Ijaduola returned in 1978 with FRCS degree and was
                        appointed a Consultant in the Unit in 1979. Dr Idewu
                        FACS came from America as a Head and Neck Oncologist in
                        the Unit but later resigned in 1984.
                        <br />
                        Full local training of Resident Doctors in ORL started
                        at the Lagos University Teaching Hospital in 1983, Drs
                        Olabiyi and Okeni returned from Britain, sat and passed
                        the Part II Fellowship of the NPMCN in Nov. 1988. Dr OA
                        Osinubi passed the Fellowship of West African College of
                        Surgeons (FWACS) in 1991, he was followed by Dr. CC
                        Nwawolo passed the Part II Fellowship of the National
                        Postgraduate Medical College of Nigeria (NPMCN),in 1992
                        (FMCORL) and was appointed as the first indigenously
                        trained Consultant in 1993. Other Residents who
                        completed training in ORL in LUTH include: Dr. BA
                        Adesanya, EA Oyewole, AO Somefun, BB Okunola, AO Imogu,
                        FE Ologe, KA Adekoya, BK Beredugo-Amadasun, Ad Olusesi,
                        BS Alabi, OO Olubi, AE Mazai, AO Ogunbase, AO Danfulani,
                        AO Akinpelu, CN Asoegwu, BA Bamigboye, AP Adeyinka, OS
                        Oguntoyinbo, CB Wanda, MA Akinola, WA Adegbiji,,
                        DSOghu,OO Olowsusi, S. Oladeji, AA Adebayo, J. Ukeje, A.
                        Abdulazeez, JA Idowu. <br />
                        The Otorhinolaryngology unit was eventually upgraded to
                        the status of a Department in the year 2008 with Prof.
                        CC Nwawolo as the pioneer HOD, he was succeeded 5 years
                        later by Prof AO Somefun in 2013 who handed over to
                        Dr.BA Bamigboye in 2021. The Department aside from
                        training her primary Resident Doctors has also trained
                        Residents from other centers due to her dual
                        accreditationstatus for both WACS and NPMCN, some of the
                        centres include: LASUTH, UITH Ilorin, OAUTHC Ile-Ife,
                        FMC Umuahia, UBTH Benin etc
                      </p>
                      <h4>Milestone</h4>
                      <p>
                        Aside being a referral centre for Clinical Posting for
                        ENT Residents in partially accredited centers, the
                        department organized the first Basic Surgical Skills
                        course for her Residents in the millienium era in 2001,
                        now domiciled with the LUTH-WACS Basic Surgical Skills
                        for all Residents. The department hosted the Faculty of
                        ORL of the National Postgraduate Medical College of
                        Nigeria Temporal Bone Dissection Course for ENT
                        Residents between 2002-2018 and the Endoscopic Sinus
                        Ssurgery Course 2009-2019.
                      </p>
                      
                  <h4>COLLABORATION</h4>
                  <p>
                  The department collaborates with the following:
                  </p>
                  <div label='collaboration'>
                    <List
                      spacing='xs'
                      size='sm'
                      center
                      icon={
                        <ThemeIcon color='teal' size={24} radius='xl'>
                          <CircleCheck size={16} />
                        </ThemeIcon>
                      }
                    >
                      {collaboration.map((data) => (
                        <List.Item key={data.name}>{data.name}</List.Item>
                      ))}
                    </List>
                  </div>
                    </Spoiler>
                  </div>
                  <div label='Services'>
                  <h4>Sub-Units in the Department</h4>
                      <p>

                      </p>
                      <div label='subunits'>
                    <List
                      spacing='xs'
                      size='sm'
                      center
                      icon={
                        <ThemeIcon color='teal' size={24} radius='xl'>
                          <CircleCheck size={16} />
                        </ThemeIcon>
                      }
                    >
                      {subunits.map((data) => (
                        <List.Item key={data.name}>{data.name}</List.Item>
                      ))}
                    </List>
                  </div>
                  <br />
                  <h4>Facilities</h4>
                    <List
                      spacing='xs'
                      size='sm'
                      center
                      icon={
                        <ThemeIcon color='teal' size={24} radius='xl'>
                          <CircleCheck size={16} />
                        </ThemeIcon>
                      }
                    >
                      {services.map((data) => (
                        <List.Item key={data.name}>{data.name}</List.Item>
                      ))}
                    </List>
                  </div>
                  <div label='Staff'>
                    <React.Fragment>
                      <h2 className='blog-one__title'>Staff Details</h2>
                      <h3>Consultants</h3>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={team1} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Prof. Abayomi O. Somefun</h3>
                          <p>
                            <span className='detail-head'>Designation:</span>{' '}
                            Honorary Consultant
                            <br />
                            {/* <span className='detail-head'>
                              Qualification:
                            </span>{' '}
                            MBBS, MPH, FWACP, Cert. Epidemiology and
                            Biostatistics
                            <br />
                            <span className='detail-head'>
                              Subspeciality:
                            </span>{' '}
                            Community Health and Primary Care, Public Health,
                            Reproductive and Family Health */}
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={team1} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Dr. Chinyere  N. Asoegwu</h3>
                          <p>
                            <span className='detail-head'>Designation:</span>{' '}
                            Honorary Consultant
                            <br />
                            {/* <span className='detail-head'>
                              Qualification:
                            </span>{' '}
                            MBBS, MSc, FMCFM, PGD-HSE, FIIM
                            <br />
                            <span className='detail-head'>
                              Subspeciality:
                            </span>{' '}
                            Occupational Health and Safety, Infection Prevention
                            and Control, Sleep Health */}
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={team1} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Dr. Babatunde A. Bamigboye</h3>
                          <p>
                            <span className='detail-head'>Designation:</span>{' '}
                            Honorary Consultant
                            <br />
                            {/* <span className='detail-head'>
                              Qualification:
                            </span>{' '}
                            Registered Nurse/Midwife, Dip in A&E Nursing,
                            Bachelor of Nursing Science */}
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={team1} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Dr. Moronke D. Akinola </h3>
                          <p>
                            <span className='detail-head'>Designation:</span> Ag
                            Hospital Consultant
                            {/* <br />
                            <span className='detail-head'>
                              Qualification:
                            </span>{' '}
                            HND, BSc, MSc */}
                          </p>
                        </div>
                      </div>
                      <h3>Registrars</h3>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={team1} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Dr. Agboola Ogunbiyi </h3>
                          <p>
                            <span className='detail-head'>Designation:</span> Ag
                            Chief Resident
                            {/* <br />
                            <span className='detail-head'>
                              Qualification:
                            </span>{' '}
                            HND, BSc, MSc */}
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={team1} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Dr. Joyce Johnson </h3>
                          <p>
                            <span className='detail-head'>Designation:</span> Ag
                            Senior Resident
                            {/* <br />
                            <span className='detail-head'>
                              Qualification:
                            </span>{' '}
                            HND, BSc, MSc */}
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={team1} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Dr. Stephen Chukwu </h3>
                          <p>
                            <span className='detail-head'>Designation:</span> Ag
                            Senior Resident
                            {/* <br />
                            <span className='detail-head'>
                              Qualification:
                            </span>{' '}
                            HND, BSc, MSc */}
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={team1} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Dr. Taofeek Mabifah  </h3>
                          <p>
                            <span className='detail-head'>Designation:</span> Ag
                            Registrar
                            {/* <br />
                            <span className='detail-head'>
                              Qualification:
                            </span>{' '}
                            HND, BSc, MSc */}
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={team1} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Dr. Ogieva Alaba </h3>
                          <p>
                            <span className='detail-head'>Designation:</span> Ag
                            Registrar
                            {/* <br />
                            <span className='detail-head'>
                              Qualification:
                            </span>{' '}
                            HND, BSc, MSc */}
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={team1} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Dr. Palangutde Dimowo </h3>
                          <p>
                            <span className='detail-head'>Designation:</span> Ag
                            Registrar
                            {/* <br />
                            <span className='detail-head'>
                              Qualification:
                            </span>{' '}
                            HND, BSc, MSc */}
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={team1} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Dr. Isioma Nsemeke  </h3>
                          <p>
                            <span className='detail-head'>Designation:</span> Ag
                            Registrar
                            {/* <br />
                            <span className='detail-head'>
                              Qualification:
                            </span>{' '}
                            HND, BSc, MSc */}
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={team1} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Dr. Oluwatobi Adetunji  </h3>
                          <p>
                            <span className='detail-head'>Designation:</span> Ag
                            Registrar
                            {/* <br />
                            <span className='detail-head'>
                              Qualification:
                            </span>{' '}
                            HND, BSc, MSc */}
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={team1} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Dr. Benjamin Idoko </h3>
                          <p>
                            <span className='detail-head'>Designation:</span> Ag
                            Registrar
                            {/* <br />
                            <span className='detail-head'>
                              Qualification:
                            </span>{' '}
                            HND, BSc, MSc */}
                          </p>
                        </div>
                      </div>
                      <h3>Audiologist</h3>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={team1} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Mrs.  Loreta Ogban </h3>
                          <p>
                            <span className='detail-head'>Designation:</span> Ag
                            Audiologist
                            {/* <br />
                            <span className='detail-head'>
                              Qualification:
                            </span>{' '}
                            HND, BSc, MSc */}
                          </p>
                        </div>
                      </div>
                      <h3>Speech Pathologist/Therapist</h3>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={team1} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Mrs.  Ebunoluwa Ogunyemi </h3>
                          <p>
                            <span className='detail-head'>Designation:</span> Ag
                            Speech Pathologist/Therapist
                            {/* <br />
                            <span className='detail-head'>
                              Qualification:
                            </span>{' '}
                            HND, BSc, MSc */}
                          </p>
                        </div>
                      </div>
                    </React.Fragment>
                  </div>
                  <div label='Contact'>
                    <React.Fragment>
                      {/* <h2 className='blog-one__title'>
                        Departmental Contact Details
                      </h2>
                      <p className='course-details__tab-text'>
                        EMAIL: familymed@luth.gov.ng
                        <br />
                        TELEPHONE NUMBER: 07084937794 – FOR OUT-PATIENTS
                        <br />
                        CLINIC HOURS: 8AM-4PM Week Days. 10AM-2PM Weekends and
                        Public Holidays (STRICTLY FOR STAFF)
                        <br />
                        LOCATION: Third Floor, Out-Patients Clinic Building
                        <br />
                        For Clinic Bookings, call above Departmental Numbers
                      </p> */}
                    </React.Fragment>
                  </div>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </Layout>
  );
};

export default EntDept;
